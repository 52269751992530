import React, { useRef, useState, useEffect, useContext, useMemo, SyntheticEvent } from "react"
import { useLocation } from "@reach/router"
import { CarouselPhotoSlide, CarouselVideoSlide } from "./CarouselSlide"
import slugify from "slugify";
import { LayoutContext } from "./layout"
import { slideSliceHasContent } from "../utilities/slideSliceHasContent"
import { useEventListener } from "../hooks/useEventListener";
import windowIfAvailable from "../utilities/windowIfAvailable";
import { getAdjacentIndex } from "../utilities/getAdjacentIndex";
import usePrevious from "../hooks/usePrevious"
import { animate, AnimationOptions, m, MotionValue, PanInfo, useDragControls, useMotionValue } from "framer-motion";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";
import { debounce } from "throttle-debounce";

const generatePositionClasses = (position: string) => {
  switch(position) {
    case "Left":
      return `sm:justify-start`
    case "Right":
      return `sm:justify-end`
    case "Center":
    default:
      return `sm:justify-center` 
  }
}

const calculateNewX = ({
  carouselRef,
  carouselSlideIndex,
  carouselSlidesCount,
  carouselWidth,
  direction,
  isEdge,
  options = {
    ignoreEdges: false,
    recalculate: false,
  },
  x,
}: {
  carouselRef: React.MutableRefObject<HTMLDivElement | undefined>,
  carouselSlideIndex: number,
  carouselSlidesCount: number,
  carouselWidth: React.MutableRefObject<number>,
  direction: number,
  isEdge: boolean,
  options: {
    ignoreEdges?: boolean,
    recalculate?: boolean
  }
  x: MotionValue<number> | number,
  // x: MotionValue<string> | string,
}) => {
  // console.log(`calculateNewX`)
  // if(!carouselWidth?.current || options.recalculate) {
    // console.log(`Recalculating width!`, { carouselWidth, options })
    carouselWidth.current = carouselRef?.current?.getBoundingClientRect()?.width ?? 0
  // }

  let newX: MotionValue<number> | number
  // let newX: MotionValue<string> | string

  // Default position calculation
  newX = -1 * carouselSlideIndex * carouselWidth.current
    // newX = `${-1 * carouselSlideIndex / (carouselSlidesCount + 2) * 100}%`

  // Override position calculations at edges
  if(isEdge && !options.ignoreEdges) {
    // Crossing right edge (going from final slide to first slide)
    if(direction < -1) newX = -1 * carouselSlidesCount * carouselWidth.current
    // if(direction < -1) {
    //   console.log(`crossing right edge`)
    //   newX = `${-1 * (carouselSlidesCount) / (carouselSlidesCount + 2) * 100}%`
    // }
    // Crossing left edge (going from first slide to final slide)
    else if(direction > 1) newX = -1 * -1 * carouselWidth.current
    // else if(direction > 1) {
    //   console.log(`crossing left edge`)
    //   newX = `${-1 * -1 / (carouselSlidesCount + 2) * 100}%`
    // }
  }

  if(isNaN(newX)) newX = x
  // console.log(carouselSlideIndex, newX)

  return newX
}

const FramerMotionInnerCarousel = ({
  caption,
  carouselDimensions,
  carouselSlideIndex,
  data,
  filteredIndex,
  firstSlideRatio,
  index,
  onScreen,
  setCarouselSlideIndex,
}: {
  caption: boolean,
  carouselDimensions: CarouselDimensions,
  carouselSlideIndex: number,
  data: any,
  filteredIndex: number,
  firstSlideRatio: number,
  index: number,
  onScreen: boolean,
  setCarouselSlideIndex: React.SetStateAction<number>
}) => {
  const layout = useContext(LayoutContext),
    {
      browser,
      touch,
    } = layout

  const client = data?.client?.document?.data,
    clientNameSlug = slugify(client?.name?.toLowerCase() ?? ``),
    { state } = useLocation(),
    stateHasClient = state?.client,
    clientNameSlugMatchesState = state?.client
      ? state.client === clientNameSlug
      : false,
    slides = data?.body?.filter((slide: any) => slideSliceHasContent(slide)),
    carouselSlidesCount = slides?.length,
    autoplay = data?.autoplay,
    speed = data?.speed,
    [hover, setHover] = useState(false),
    hoverRef = useRef(hover),
    carouselPosition = data.position,
    carouselPositionClasses = generatePositionClasses(carouselPosition),
    adjacentIndices = [
      getAdjacentIndex(carouselSlideIndex, carouselSlidesCount, -1),
      getAdjacentIndex(carouselSlideIndex, carouselSlidesCount, 1)
    ],
    previousCarouselSlideIndex = usePrevious(carouselSlideIndex),
    [timeoutId, setTimeoutIdState] = useState<number | undefined>(),
    timeoutIdRef = useRef<number | undefined>(),
    wasUserInteractionRef = useRef(true),
    inMotionRef = useRef<boolean>(false)

  timeoutIdRef.current = timeoutId

  useEffect(() => {
    hoverRef.current = hover
  }, [hover])

  const handleMouseEnter = autoplay && speed ? () => setHover(true) : undefined,
    handleMouseLeave = hover ? () => setHover(false) : undefined

  // Intercept calls to setTimeoutId to ensure the last stored timeoutId is cleared
  const setTimeoutId = (id: number | undefined, log = ``) => {
    if(typeof timeoutIdRef?.current !== `undefined`) clearTimeout(timeoutIdRef.current)
    // const now = performance.now()
    // console.log(log, `${Math.round(now - (previousNow.current ?? 0))}ms`, `ref.current`, timeoutIdRef?.current, `new`, id)
    // previousNow.current = now
    setTimeoutIdState(id)
  }

  const next = () => {
      // Break if we are currently in motion
      if(inMotionRef.current) {
        // Reset wasUserInteractionRef when breaking
        wasUserInteractionRef.current = true
        return
      }
      // If there’s only one slide we can quit
      if(carouselSlidesCount === 1) {
        // Reset wasUserInteractionRef when breaking
        wasUserInteractionRef.current = true
        return
      }
      // Set undefined so new timeout is created
      setTimeoutId(undefined, `next`)
      // Increment index
      setCarouselSlideIndex((prevState) => (prevState + 1) % carouselSlidesCount)
    },
    previous = () => {
      // Break if we are currently in motion
      if(inMotionRef.current) return
      // If there’s only one slide we can quit
      if(carouselSlidesCount === 1) return
      // Set undefined so new timeout is created
      setTimeoutId(undefined, `prev`)
      // Decrement index
      setCarouselSlideIndex((prevState) => {
        const newIndex = (prevState - 1) % carouselSlidesCount
        return newIndex < 0
          ? carouselSlidesCount - 1
          : newIndex
      })
    },
    // Memoize throttled state setters for mobile
    memoNext = useMemo(() => next, []),
    memoPrevious = useMemo(() => previous, [])

  useEventListener(`keyup`, (e) => {
    if(!onScreen) return

    const clientIndex = document.documentElement.getAttribute(`data-client`)
      ? parseInt(document.documentElement.getAttribute(`data-client`) ?? `0`)
      : 0
    if(clientIndex !== filteredIndex) return

    switch(e.code) {
      case `ArrowLeft`:
        memoPrevious()
        break
      case `ArrowRight`:
        memoNext()
        break
      default:
    }
  }, windowIfAvailable(), { passive: true })

  const [dragging, setDragging] = useState(false),
    draggable = carouselSlidesCount > 1,
    shouldAutoplay = autoplay && speed && onScreen && !dragging,
    // { ref: carouselRef, inView, entry } = useInView(),
    carouselRef = useRef<HTMLDivElement>(),
    carouselWidth = useRef<number>(0),
    controls = useDragControls(),
    x = useMotionValue(0),
    // x = useMotionValue(`0%`),
    direction = carouselSlideIndex - previousCarouselSlideIndex,
    isEdge = Math.abs(direction) > 1,
    startDrag = (e: SyntheticEvent<HTMLDivElement, PointerEvent>) => {
      // Break if we are currently in motion
      if(inMotionRef.current) return
      controls.start(e.nativeEvent)
    },
    handlePointerUp = (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
      // Skip pointer handling while dragging
      if(dragging) return

      // Otherwise handle clicks
      if((e.target as HTMLElement).classList.contains(`prev`)) memoPrevious()
      else if((e.target as HTMLElement).classList.contains(`next`)) memoNext()
    },
    onComplete = () => inMotionRef.current = false,
    onUpdate = () => { if(!inMotionRef.current) inMotionRef.current = true },
    transition: AnimationOptions<any> = useMemo(() => { return {
      type: `spring`,
      bounce: 0,
      // If change was caused by a user interaction, animate, otherwise transition instantaneously
      duration: wasUserInteractionRef.current ? 0.4 : 0,
      // When a transition completes, if the slide is an edge, transition instantly to the default position
      onComplete: () => {
        if(isEdge) {
          animate(x, calculateNewX({ carouselRef, carouselSlideIndex, carouselSlidesCount, carouselWidth, direction, isEdge, options: { ignoreEdges: true }, x }), {
            duration: 0,
            onComplete,
            onUpdate,
          })
        } else {
          inMotionRef.current = false
          // Reset wasUserInteractionRef
          wasUserInteractionRef.current = true
        }
      },
      onUpdate,
    }}, [isEdge, wasUserInteractionRef.current, x]),
    handleDragStart = () => setDragging(true),
    handleDragEnd = (e: Event, dragProps: PanInfo) => {
      const clientWidth = carouselWidth.current ?? 0,
        { offset } = dragProps

      // console.log(e, dragProps, offset, clientWidth)

      if(offset.x > 0.5 * clientWidth) {
        // console.log(`drag previous`)
        memoPrevious()
      }
      else if(offset.x < -0.5 * clientWidth) {
        // console.log(`drag next`)
        memoNext()
      }
      else {
        // console.log(`animate back`)
        animate(x, calculateNewX({ carouselRef, carouselSlideIndex, carouselSlidesCount, carouselWidth, direction, isEdge, options: {}, x }), transition)
      }

      setDragging(false)
    }

  // Instantly reposition current slide on resize
  useEventListener(`resize`, () => {
    // animate(x, calculateNewX({ carouselRef, carouselSlideIndex, carouselSlidesCount, carouselWidth, direction, isEdge, options: { recalculate: true }, x }), { ...transition, ...{ duration: 0 }})
    // debounce(50, () => {
    setTimeout(() => {
      animate(x, calculateNewX({ carouselRef, carouselSlideIndex, carouselSlidesCount, carouselWidth, direction, isEdge, options: { recalculate: true }, x }), { ...transition, ...{ duration: 0 }})
    }, 0)
    // })
  }, windowIfAvailable())

  useDebouncedEffect(() => {
    if(shouldAutoplay) {
      // If should autoplay and timeoutId exists, return
      if(timeoutId) {
        // console.log(`should autoplay but timeoutId exists, doing nothing`)
        return
      }
      // Otherwise, need to start a timer for autoplay
      setTimeoutId(
        window.setTimeout(() => {
          if(onScreen) {
            // Set wasUserInteractionRef false
            wasUserInteractionRef.current = false
            memoNext()
            if(hoverRef.current) {
              // Trigger cursor update
              const event = document.createEvent(`MouseEvent`)
              event.initMouseEvent(
                `pointermove`,
                true /* bubble */,
                true /* cancelable */,
                window, null,
                0, 0, window.cursorPosition.x, window.cursorPosition.y, /* coordinates */
                false, false, false, false, /* modifier keys */
                0 /*left*/, null
              )
              window.cursorPosition.target.dispatchEvent(event)
            }
          }
        }, 1000 * speed), `shouldAutoplay`
      )
    } else {
      // If should not autoplay, clear existing timeoutId
      if(timeoutId) setTimeoutId(undefined, `!shouldAutoplay`)
      else {
        // console.log(`!should autoplay but no timeoutId exists, doing nothing`)
      }
    }

    return () => clearTimeout(timeoutId)
  }, [carouselSlideIndex, shouldAutoplay, speed, timeoutId], 10)


  const captionContent = (
    <button
      className="group inline-flex p-2.5 whitespace-nowrap"
      onClick={memoNext}
    >
      <span className="sm:hidden pointer-events-none mr-2.5 text-black text-opacity-100 tabular-nums">{`${index + 1}`.padStart(2, `0`)}</span>
      {client?.name && (<span className="sm:hidden pointer-events-none mr-2.5 text-black text-opacity-40 mouse:group-hover:text-opacity-100 transition-colors duration-200">{client.name}</span>)}
      <span className="pointer-events-none mr-2.5 tabular-nums text-black text-opacity-40 mouse:group-hover:text-opacity-100 transition-colors duration-200">
        {carouselSlideIndex + 1}/{slides.length}
      </span>
      {slides.length > 1 && (`→`)}
    </button>
  )

  useEffect(() => {
    // console.log(`do controls animate(calculateNewX)`)
    const controls = animate(x, calculateNewX({ carouselRef, carouselSlideIndex, carouselSlidesCount, carouselWidth, direction, isEdge, options: {}, x }), transition)
    return controls.stop
  }, [carouselSlideIndex])

  const stateOpacityClasses = stateHasClient
    ? clientNameSlugMatchesState
      ? `opacity-100`
      : `opacity-0`
    : `opacity-100`

  const carouselSizeClasses = carouselDimensions.size === `Large`
    ? `relative w-auto sm:w-full h-auto sm:h-auto mx-auto`
    : `relative w-auto sm:w-1/2 sm:min-w-max h-auto sm:h-full mx-auto sm:mx-0 sm:flex sm:justify-center`

  const pointerTargetStyles = {
      width: `calc(100 * var(--carousel-size) * var(--carousel-dimension) * min(1, var(--first-slide-ratio)))`,
      minWidth: carouselDimensions.size !== `Large`
        ? `calc(var(--mobile) * 100 * var(--carousel-size) * var(--carousel-dimension) * min(1, var(--first-slide-ratio)))`
        : undefined,
      height: `calc(100 * var(--carousel-size) * var(--carousel-dimension) / max(1, var(--first-slide-ratio)))`
    },
    boxShadowStyles = { boxShadow: `inset 0 0 0 0.5px white` },
    captionStyles = { width: `calc(100 * var(--carousel-size) * var(--carousel-dimension) * min(1, var(--first-slide-ratio)))` }

  const motionProps = useMemo(() => draggable && {
    drag: `x`,
    dragControls: controls,
    dragElastic: 0.3,
    onDragEnd: handleDragEnd,
    onDragStart: handleDragStart,
  }, [controls, draggable, handleDragEnd])

  {/* Carousel opacity is controlled by location state */}
  return (
    <div className={`${stateOpacityClasses} transition-opacity duration-300 delay-700`}>
      {/* Carousel position classes all sm: */}
      <div className={`carousel ${clientNameSlug} pointer-events-none flex relative items-center ${carouselPositionClasses}`}>
        {/* Carousel */}
        <div className={carouselSizeClasses}>
          {/* Left spacer for non-large carousels */}
          {carouselDimensions.size !== `Large` && (
            <div
              className="hidden sm:block min-w-[50px]"
            />
          )}
          {/* Pointer target */}
          <div
            ref={carouselRef}
            className={`relative ${carouselSlidesCount <= 1 ? `cursor` : ``} touch-pan-y overflow-hidden pointer-events-auto`}
            data-autoplay={shouldAutoplay}
            data-cursor={carouselSlidesCount <= 1 ? `1/1` : undefined}
            style={pointerTargetStyles}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* Box shadow to hide partial pixel edge of prev/next slide */}
            {carouselDimensions.size !== `Large` && (
              <div
                className="absolute inset-0 w-full h-full z-10 pointer-events-none"
                style={boxShadowStyles}
              />
            )}
            {/* Drag/prev/next targets when slide count > 1 */}
            {carouselSlidesCount > 1 ? (
              <div
                className="absolute inset-0 w-full h-full"
                onPointerDown={startDrag}
                onPointerUp={handlePointerUp}
              >
                {/* Disable cursor during drag and for touch devices */}
                {/* Don’t add classes until we are in browser to prevent hydration errors */}
                <div
                  className={`${browser && !dragging && !touch ? `cursor` : ``} prev absolute top-0 left-0 z-10 w-1/2 h-full`}
                  data-autoplay={shouldAutoplay}
                  data-cursor={dragging ? `•` : carouselSlidesCount > 1 ? adjacentIndices[0] + 1 : `1/1`}
                />
                <div
                  className={`${browser && !dragging && !touch ? `cursor` : ``} next absolute top-0 left-1/2 z-10 w-1/2 h-full`}
                  data-autoplay={shouldAutoplay}
                  data-cursor={dragging ? `•` : carouselSlidesCount > 1 ? adjacentIndices[1] + 1 : `1/1`}
                />
              </div>
            ) : null}
            {/* Slides container */}
            <div
              className={`pointer-events-none relative h-full ${carouselSlidesCount > 1 ? `-left-full` : ``}`}
              style={{ width: `${100 * (carouselSlidesCount + 2)}%` }}
            >
              <m.div
                className="flex h-full w-full will-change-transform"
                style={{ x }}
                {...motionProps}
              >
                {/* Clone last slide when slide count > 1 */}
                {carouselSlidesCount > 1 ?
                  slides[slides.length - 1].slice_type === `slide` ? (
                    <CarouselPhotoSlide
                      carouselSlidesCount={carouselSlidesCount}
                      home={false}
                      key={`${slides[slides.length - 1].id}-clone`}
                      slide={slides[slides.length - 1]}
                    />
                  ) : (
                    <CarouselVideoSlide
                      active={carouselSlideIndex === slides.length}
                      adjacentActive={carouselSlideIndex === 0}
                      carouselSlidesCount={carouselSlidesCount}
                      dragging={(carouselSlideIndex === slides.length || carouselSlideIndex === 0) && dragging}
                      home={false}
                      key={`${slides[slides.length - 1].id}-clone`}
                      onScreen={onScreen}
                      slide={slides[slides.length - 1]}
                    />
                  ) : null}
                {/* Slides */}
                {slides.map((slide: any, i: number) => {
                  if(slide.slice_type === `slide`) {
                    return (
                      <CarouselPhotoSlide
                        carouselSlidesCount={carouselSlidesCount}
                        home={false}
                        key={slide.id}
                        slide={slide}
                      />
                    )
                  } else {
                    const active = carouselSlideIndex === i,
                      previousActive = adjacentIndices[0] === i,
                      nextActive = adjacentIndices[1] === i

                    return (
                      <CarouselVideoSlide
                        active={active}
                        adjacentActive={previousActive || nextActive}
                        carouselSlidesCount={carouselSlidesCount}
                        dragging={(previousActive || active || nextActive) && dragging}
                        home={false}
                        key={slide.id}
                        onScreen={onScreen}
                        slide={slide}
                      />
                    )
                  }

                })}
                {/* Clone first slide when slide count > 1 */}
                {carouselSlidesCount > 1 ?
                  slides[0].slice_type === `slide` ? (
                    <CarouselPhotoSlide
                      carouselSlidesCount={carouselSlidesCount}
                      home={false}
                      key={`${slides[0].id}-clone`}
                      slide={slides[0]}
                    />
                  ) : (
                    <CarouselVideoSlide
                      active={carouselSlideIndex === -1}
                      adjacentActive={carouselSlideIndex === slides.length - 1}
                      carouselSlidesCount={carouselSlidesCount}
                      dragging={(carouselSlideIndex === -1 || carouselSlideIndex === slides.length - 1) && dragging}
                      home={false}
                      key={`${slides[0].id}-clone`}
                      onScreen={onScreen}
                      slide={slides[0]}
                    />
                  ) : null}
              </m.div>
            </div>
          </div>
          {/* Right spacer for non-large carousels */}
          {carouselDimensions.size !== `Large` && (
            <div className="hidden sm:block min-w-[50px]" />
          )}
        </div>
      </div>
      {/* Caption */}
      {caption && (
        <div className={`caption flex justify-center ${carouselPositionClasses} transition-opacity duration-200`}>
          <div className="w-1/2 min-w-max flex justify-center">
            {/* Left spacer for non-large carousels */}
            {carouselDimensions.size !== `Large` && (
              <div className="hidden sm:block min-w-[50px]" />
            )}
            <div style={captionStyles}>
              {captionContent}
            </div>
            {/* Right spacer for non-large carousels */}
            {carouselDimensions.size !== `Large` && (
              <div className="hidden sm:block min-w-[50px]" />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const FramerMotionInnerCarouselMemo = React.memo(FramerMotionInnerCarousel)

export { FramerMotionInnerCarouselMemo as FramerMotionInnerCarousel }