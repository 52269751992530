import React, { useRef, useState, useCallback, useContext, useMemo, SyntheticEvent } from "react"
import { useLocation } from "@reach/router"
import { CarouselPhotoSlide, CarouselVideoSlide } from "./CarouselSlide"
import slugify from "slugify";
import { LayoutContext } from "./layout"
import { getRatio } from "../utilities/getRatio"
import { slideSliceHasContent } from "../utilities/slideSliceHasContent"
import { useEventListener } from "../hooks/useEventListener";
import windowIfAvailable from "../utilities/windowIfAvailable";
import { getAdjacentIndex } from "../utilities/getAdjacentIndex";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";
import { FramerMotionInnerCarousel } from "./FramerCarousel";

const generatePositionClasses = (position: string) => {
  switch(position) {
    case "Left":
      return `sm:justify-start`
    case "Right":
      return `sm:justify-end`
    case "Center":
    default:
      return `sm:justify-center` 
  }
}

type CarouselSize = "Small" | "Medium" | "Large"

type CarouselDimensions = {
  dimension: string,
  ratio: number,
  size: CarouselSize,
}

// Use carousel size and first slide ratio to generate carousel width and height
const getCarouselWidthAsPercentageOfViewport = (size: CarouselSize, ratio: number): CarouselDimensions => {
  switch(size) {
    case "Small":
      return ratio >= 1
        ? { // Landscape images are 30% vw
          dimension: `var(--vw, 1vw)`,
          ratio: 0.3,
          size,
        } : { // Portrait images are 45% vh
          dimension: `var(--vh, 1vh)`,
          ratio: 0.45,
          size,
        }
    case "Medium":
      return ratio >= 1
        ? { // Landscape images are 45% vw
          dimension: `var(--vw, 1vw)`,
          ratio: 0.45,
          size,
        } : { // Portrait images are 70% vh
          dimension: `var(--vh, 1vh)`,
          ratio: 0.7,
          size,
        }
    case "Large":
    default:
      return { // Images are 100% vw regardless of orientation
        dimension: `var(--vw, 1vw)`,
        ratio: 1,
        size: "Large",
      }
  }
}

const CarouselStyle = ({
    carouselDimensions,
    firstSlideRatio,
    index
  }: {
    carouselDimensions: CarouselDimensions,
    firstSlideRatio: number,
    index: number
  }) => (
    <style dangerouslySetInnerHTML={{ __html: `
      [data-carousel="${index}"] {
        --carousel-size: ${carouselDimensions.ratio};
        --carousel-dimension: ${carouselDimensions.dimension};
        --first-slide-ratio: ${firstSlideRatio};
      }

      @media screen and (max-width: 699px) {
        [data-carousel="${index}"] {
          --carousel-size: ${carouselDimensions.size === `Large` ? 1 : 0.8};
          --carousel-dimension: var(--vw, 1vw);
        }
      }` }} />
  ),
  CarouselStyleMemo = React.memo(CarouselStyle)

const Carousel = ({
  caption,
  data,
  index,
  length,
  filtered,
  filteredIndex,
  nearScreen,
  onScreen,
}: {
  caption: boolean,
  data: any,
  index: number,
  length: number,
  filtered: boolean,
  filteredIndex: number,
  nearScreen: boolean,
  onScreen: boolean
}) => {
  const slides = data?.body?.filter((slide: any) => slideSliceHasContent(slide)),
    firstSlideRatio = getRatio(slides[0]),
    carouselDimensions = useMemo(() => getCarouselWidthAsPercentageOfViewport(data.size, firstSlideRatio), [data.size, firstSlideRatio]),
    [carouselSlideIndex, setCarouselSlideIndex] = useState(0),
    client = data?.client?.document?.data,
    clientName = client?.name ?? ``,
    clientNameSlug = slugify(clientName.toLowerCase())

  const centeredCarouselPaddingStyles = { height: `calc((100 * var(--vh, 1vh) - 100 * var(--carousel-size) * var(--carousel-dimension) / max(1, var(--first-slide-ratio))) / 2)` },
    slideHeightStyles = { height: `calc(100 * var(--carousel-size) * var(--carousel-dimension) / max(1, var(--first-slide-ratio)))` }

  // Top padding for all carousels
  const topPadding = filteredIndex === 0
    ? carouselDimensions.size === `Large`
      // No top padding for the first carousel if it is large
      ? null
      // Padding to vertically center the first carousel on screen otherwise
      : (
          <>
            <div
              className="hidden sm:block"
              style={centeredCarouselPaddingStyles}
            />
            {/* Default mobile padding for first carousel */}
            <div className="block sm:hidden h-[120px]" />
          </>
        )
      // Standard padding for remaining carousels
    : <div className="h-[120px] sm:h-[150px]" />,
    // Desktop bottom padding to vertically center the last carousel
    bottomPadding = filteredIndex === length - 1 && carouselDimensions.size !== `Large` ? (
      <div
        className="hidden sm:block sm:-mt-8"
        style={centeredCarouselPaddingStyles}
      />
    ) : null,
    interactiveCarousel = (
      <CarouselSelectorMemo
        caption={caption}
        carouselDimensions={carouselDimensions}
        carouselSlideIndex={carouselSlideIndex}
        data={data}
        filteredIndex={filteredIndex}
        firstSlideRatio={firstSlideRatio}
        index={index}
        onScreen={onScreen}
        setCarouselSlideIndex={setCarouselSlideIndex}
      />
    ),
    // Carousel placeholder
    carouselPlaceholder = (
      <div className="relative">
        {/* Slide placeholder */}
        <div style={slideHeightStyles} />
        {/* Caption placeholder */}
        {caption
          ? <div className="p-2.5">&nbsp;</div>
          : null
        }
      </div>
    )

  return (
    <div
      className={filtered ? `` : `hidden`}
      data-carousel={`${index}`}
      data-size={carouselDimensions.size.toLowerCase()}
    >
      {/* CSS variables */}
      <CarouselStyleMemo
        carouselDimensions={carouselDimensions}
        firstSlideRatio={firstSlideRatio}
        index={index}
      />
      {/* Top padding */}
      {topPadding}
      {/* Carousel */}
      <div
        data-client={clientNameSlug}
        data-filtered-index={filteredIndex}
        data-formal={clientName}
        data-index={index}
      >
        {(filtered && nearScreen)
          ? interactiveCarousel
          : carouselPlaceholder}
      </div>
      {/* Bottom padding */}
      {bottomPadding}
    </div>
  )
}

const CarouselSelector = (props: any) => {
  const layout = useContext(LayoutContext),
    { reduceMotion } = layout

  // return <CSSInnerCarouselMemo {...props} />
  // return <FramerMotionInnerCarousel {...props} />

  return reduceMotion
    ? <CSSInnerCarouselMemo {...props} />
    : <FramerMotionInnerCarousel {...props} />
}

const CSSInnerCarousel = ({
  caption,
  carouselDimensions,
  carouselSlideIndex,
  data,
  filteredIndex,
  firstSlideRatio,
  index,
  onScreen,
  setCarouselSlideIndex,
}: {
  caption: boolean,
  carouselDimensions: CarouselDimensions,
  carouselSlideIndex: number,
  data: any,
  filteredIndex: number,
  firstSlideRatio: number,
  index: number,
  onScreen: boolean,
  setCarouselSlideIndex: React.SetStateAction<number>
}) => {
  const client = data?.client?.document?.data,
    clientName = client?.name ?? ``,
    clientNameSlug = slugify(clientName.toLowerCase()),
    { state } = useLocation(),
    stateHasClient = state?.client,
    clientNameSlugMatchesState = state?.client
      ? state.client === clientNameSlug
      : false,
    slides = data?.body?.filter((slide: any) => slideSliceHasContent(slide)),
    carouselSlidesCount = slides?.length,
    autoplay = data?.autoplay,
    speed = data?.speed,
    carouselPosition = data.position,
    carouselPositionClasses = generatePositionClasses(carouselPosition),
    carouselImageSizes = `(min-width: 700px) ${100 * carouselDimensions.ratio * Math.min(1, firstSlideRatio)}${carouselDimensions.dimension.indexOf(`vw`) > 1 ? `vw` : `vh`}, ${carouselDimensions.size === `Large` ? `100vw` : `80vw`}`,
    adjacentIndices = [
      getAdjacentIndex(carouselSlideIndex, carouselSlidesCount, -1),
      getAdjacentIndex(carouselSlideIndex, carouselSlidesCount, 1)
    ],
    [timeoutId, setTimeoutIdState] = useState<number | undefined>(),
    timeoutIdRef = useRef<number | undefined>(),
    wasUserInteractionRef = useRef(true),
    inMotionRef = useRef<boolean>(false)

  timeoutIdRef.current = timeoutId

    // Intercept calls to setTimeoutId to ensure the last stored timeoutId is cleared
    const setTimeoutId = (id: number | undefined, log = ``) => {
      if(typeof timeoutIdRef?.current !== `undefined`) clearTimeout(timeoutIdRef.current)
      setTimeoutIdState(id)
    }

    const next = () => {
        // Break if we are currently in motion
        if(inMotionRef.current) {
          // Reset wasUserInteractionRef when breaking
          wasUserInteractionRef.current = true
          return
        }
        // If there’s only one slide we can quit
        if(carouselSlidesCount === 1) {
          // Reset wasUserInteractionRef when breaking
          wasUserInteractionRef.current = true
          return
        }
        // Set undefined so new timeout is created
        setTimeoutId(undefined, `next`)
        // Increment index
        setCarouselSlideIndex((prevState) => (prevState + 1) % carouselSlidesCount)
      },
      previous = () => {
        // Break if we are currently in motion
        if(inMotionRef.current) return
        // If there’s only one slide we can quit
        if(carouselSlidesCount === 1) return
        // Set undefined so new timeout is created
        setTimeoutId(undefined, `prev`)
        // Decrement index
        setCarouselSlideIndex((prevState) => {
          const newIndex = (prevState - 1) % carouselSlidesCount
          return newIndex < 0
            ? carouselSlidesCount - 1
            : newIndex
        })
      },
      // Memoize throttled state setters for mobile
      memoNext = useMemo(() => next, []),
      memoPrevious = useMemo(() => previous, [])

    useEventListener(`keyup`, (e) => {
      if(!onScreen) return

      const clientIndex = document.documentElement.getAttribute(`data-client`)
        ? parseInt(document.documentElement.getAttribute(`data-client`) ?? `0`)
        : 0
      if(clientIndex !== filteredIndex) return

      switch(e.code) {
        case `ArrowLeft`:
          memoPrevious()
          break
        case `ArrowRight`:
          memoNext()
          break
        default:
      }
    }, windowIfAvailable(), { passive: true })

  const shouldAutoplay = autoplay && speed && onScreen,
    handleMouseUp = (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => {
      if((e.target as HTMLElement).classList.contains(`prev`)) memoPrevious()
      else if((e.target as HTMLElement).classList.contains(`next`)) memoNext()
    }

  useDebouncedEffect(() => {
    if(shouldAutoplay) {
      // If should autoplay and timeoutId exists, return
      if(timeoutId) {
        // console.log(`should autoplay but timeoutId exists, doing nothing`)
        return
      }
      // Otherwise, need to start a timer for autoplay
      setTimeoutId(
        window.setTimeout(() => {
          if(onScreen) {
            // Set wasUserInteractionRef false
            wasUserInteractionRef.current = false
            memoNext()
            // Trigger cursor update
            const event = document.createEvent(`MouseEvent`)
            event.initMouseEvent(
              `pointermove`,
              true /* bubble */,
              true /* cancelable */,
              window, null,
              0, 0, window.cursorPosition.x, window.cursorPosition.y, /* coordinates */
              false, false, false, false, /* modifier keys */
              0 /*left*/, null
            )
            window.cursorPosition.target.dispatchEvent(event)
          }
        }, 1000 * speed), `shouldAutoplay`
      )
    } else {
      // If should not autoplay, clear existing timeoutId
      if(timeoutId) setTimeoutId(undefined, `!shouldAutoplay`)
      else {
        // console.log(`!should autoplay but no timeoutId exists, doing nothing`)
      }
    }

    return () => clearTimeout(timeoutId)
  }, [carouselSlideIndex, shouldAutoplay, speed, timeoutId], 10)


  const captionContent = (
    <button
      className="group inline-flex p-2.5 whitespace-nowrap"
      onClick={memoNext}
    >
      <span className="sm:hidden pointer-events-none mr-2.5 text-black text-opacity-100 tabular-nums">{`${index + 1}`.padStart(2, `0`)}</span>
      {client?.name && (<span className="sm:hidden pointer-events-none mr-2.5 text-black text-opacity-40 mouse:group-hover:text-opacity-100 transition-colors duration-200">{client.name}</span>)}
      <span className="pointer-events-none mr-2.5 tabular-nums text-black text-opacity-40 mouse:group-hover:text-opacity-100 transition-colors duration-200">
        {carouselSlideIndex + 1}/{slides.length}
      </span>
      {slides.length > 1 && (`→`)}
    </button>
  )

  const stateOpacityClasses = stateHasClient
    ? clientNameSlugMatchesState
      ? `opacity-100`
      : `opacity-0`
    : `opacity-100`

  const carouselSizeClasses = carouselDimensions.size === `Large`
    ? `relative w-auto sm:w-full h-auto sm:h-auto mx-auto`
    : `relative w-auto sm:w-1/2 sm:min-w-max h-auto sm:h-full mx-auto sm:mx-0 sm:flex sm:justify-center`

  const pointerTargetStyles = {
      width: `calc(100 * var(--carousel-size) * var(--carousel-dimension) * min(1, var(--first-slide-ratio)))`,
      minWidth: carouselDimensions.size !== `Large`
        ? `calc(var(--mobile) * 100 * var(--carousel-size) * var(--carousel-dimension) * min(1, var(--first-slide-ratio)))`
        : undefined,
      height: `calc(100 * var(--carousel-size) * var(--carousel-dimension) / max(1, var(--first-slide-ratio)))`
    },
    boxShadowStyles = { boxShadow: `inset 0 0 0 0.5px white` },
    captionStyles = { width: `calc(100 * var(--carousel-size) * var(--carousel-dimension) * min(1, var(--first-slide-ratio)))` }

  const generateSlide = useCallback(
    (slide: any, index: number, i: number) => {
      if(slide.slice_type === `slide`) {
        return (
          <CarouselPhotoSlide
            active={carouselSlidesCount === 1 ? i === 0 : i === 1}
            home={false}
            key={slide.id}
            performance={true}
            slide={slide}
          />
        )
      } else {
        return (
          <CarouselVideoSlide
            active={carouselSlidesCount === 1 ? i === 0 : i === 1}
            adjacentActive={carouselSlidesCount === 1 ? false : i !== 1}
            carouselDimensions={carouselDimensions}
            home={false}
            key={slide.id}
            onScreen={onScreen}
            performance={true}
            slide={slide}
          />
        )
      }
    },
    [carouselImageSizes, carouselDimensions, carouselSlidesCount, onScreen]
  )

  const windowedIndices = carouselSlidesCount === 1 ? [carouselSlideIndex] : [adjacentIndices[0], carouselSlideIndex, adjacentIndices[1]],
    windowedSlides = useMemo(() => windowedIndices.map((index, i) => generateSlide(slides[index], index, i)), [slides, windowedIndices])

  {/* Carousel opacity is controlled by location state */}
  return (
    <div className={`${stateOpacityClasses} transition-opacity duration-300 delay-700`}>
      {/* Carousel position classes all sm: */}
      <div className={`carousel ${clientNameSlug} pointer-events-none flex relative items-center ${carouselPositionClasses}`}>
        {/* Carousel */}
        <div className={carouselSizeClasses}>
          {/* Left spacer for non-large carousels */}
          {carouselDimensions.size !== `Large` && (
            <div
              className="hidden sm:block min-w-[50px]"
            />
          )}
          {/* Pointer target */}
          <div
            className={`relative ${carouselSlidesCount <= 1 ? `cursor` : ``} touch-pan-y overflow-hidden pointer-events-auto`}
            data-autoplay={shouldAutoplay}
            data-cursor={carouselSlidesCount <= 1 ? `1/1` : undefined}
            style={pointerTargetStyles}
          >
            {/* Box shadow to hide partial pixel edge of prev/next slide */}
            {carouselDimensions.size !== `Large` && (
              <div
                className="absolute inset-0 w-full h-full z-10 pointer-events-none"
                style={boxShadowStyles}
              />
            )}
            {/* Drag/prev/next targets when slide count > 1 */}
            {carouselSlidesCount > 1 ? (
              <div
                className="absolute inset-0 w-full h-full"
                onPointerUp={handleMouseUp}
              >
                {/* Disable cursor for touch devices */}
                <div
                  className="touch:hidden cursor prev absolute top-0 left-0 z-10 w-1/2 h-full"
                  data-autoplay={shouldAutoplay}
                  data-cursor={carouselSlidesCount > 1 ? adjacentIndices[0] + 1 : `1/1`}
                />
                <div
                  className="touch:hidden cursor next absolute top-0 left-1/2 z-10 w-1/2 h-full"
                  data-autoplay={shouldAutoplay}
                  data-cursor={carouselSlidesCount > 1 ? adjacentIndices[1] + 1 : `1/1`}
                />
                <div className="mouse:hidden prev absolute top-0 left-0 z-10 w-1/2 h-full" />
                <div className="mouse:hidden next absolute top-0 left-1/2 z-10 w-1/2 h-full" />
              </div>
            ) : null}
            {/* Slides */}
            {windowedSlides}
          </div>
          {/* Right spacer for non-large carousels */}
          {carouselDimensions.size !== `Large` && (
            <div className="hidden sm:block min-w-[50px]" />
          )}
        </div>
      </div>
      {/* Caption */}
      {caption && (
        <div className={`caption flex justify-center ${carouselPositionClasses} transition-opacity duration-200`}>
          <div className="w-1/2 min-w-max flex justify-center">
            {/* Left spacer for non-large carousels */}
            {carouselDimensions.size !== `Large` && (
              <div className="hidden sm:block min-w-[50px]" />
            )}
            <div style={captionStyles}>
              {captionContent}
            </div>
            {/* Right spacer for non-large carousels */}
            {carouselDimensions.size !== `Large` && (
              <div className="hidden sm:block min-w-[50px]" />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const CarouselMemo = React.memo(Carousel),
  CarouselSelectorMemo = React.memo(CarouselSelector),
  CSSInnerCarouselMemo = React.memo(CSSInnerCarousel)

export {
  CarouselMemo as Carousel
}
